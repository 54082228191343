<template>
  <div>
    <b-row>
      <add-resource
        resource-name="CommercialController"
        add-route-name="add-business"
      />
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="5">
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>
    <b-row>
      <b-col>
        <business-table
          ref="parentTable"
          v-bind="{
            fetchData:myProvider,
            currentPage:pagination.currentPage,
            perPage:pagination.perPage,
          }"
        />
        <pagination
          :total-rows="pagination.totalRows"
          :per-page="pagination.perPage"
          :current-page.sync="pagination.currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import BusinessTable from '@/common/components/Business/BusinessTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'AnnouncementsList',
  components: {
    AddResource,
    Search,
    Pagination,
    BusinessTable,
    SearchButton,
  },
  data() {
    return {
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 10,
      },
      searchQuery: '',
      loading: true,
    }
  },
  methods: {
    myProvider() {
      const promise = this.$entities.get('internalops/commercial_entity/search', {
        params: {
          key: this.searchQuery,
          page_number: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        },
      })
      return promise.then(res => {
        const items = res.data.data.data
        this.pagination.perPage = parseInt(res.data.data.per_page, 10)
        this.pagination.totalRows = res.data.data.total
        return items || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
