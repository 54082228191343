<template>
  <b-table
    ref="table"
    :no-provider-sorting="true"
    :items="fetchData"
    :fields="tableColumns"
    :current-page="currentPage"
    :per-page="perPage"
    aria-busy="true"
    class="position-relative"
    responsive="lg"
    primary-key="id"
    empty-text="No Business found"
    show-empty
  >
    <!-- Column: User -->
    <template #cell(name)="data">
      <b-media vertical-align="center">
        <template #aside>
          <b-avatar
            size="32"
            :src="`${$entitiesImagesURL}${data.item.logo}`"
            :text="avatarText(data.item.name)"
          />
        </template>
        <div
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.name.slice(0,50) }}
        </div>
        <small class="text-muted">#{{ data.item.id }}</small>
      </b-media>
    </template>

    <!-- Column: Phone -->
    <template #cell(phone)="data">
      {{ data.item.phone_number }}
    </template>

    <!-- Column: City -->
    <template #cell(SubClassification)="data">
      {{ data.item.classification }}
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item :to="{ name: 'view-business', params: { id: data.item.id } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">View</span>
        </b-dropdown-item>

        <b-dropdown-item
          :to="{ name: 'edit-business', params: { id: data.item.id } }"
        >
          <feather-icon icon="EditIcon" />
          <span> Edit </span>
        </b-dropdown-item>

        <b-dropdown-item>
          <feather-icon
            v-if="data.item.status"
            icon="CloseIcon"
          />
          <feather-icon
            v-if="data.item.status"
            icon="ToggleRightIcon"
          />
          <feather-icon
            v-else
            icon="ToggleLeftIcon"
          />
          <span
            v-if="data.item.status"
            @click="deactivateEntity(data.item.id,data.item)"
          > Reject </span>
          <span
            v-else
            @click="activateEntity(data.item.id,data.item)"
          > Approve </span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteRecord(deleteEndPoint,data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'
import changeEntityStatus from '@/common/compositions/common/changeEntityStatus'

export default {
  name: 'BusinessTable',
  props: {
    fetchData: { type: Function, default: null },
    currentPage: { type: Number, default: 1 },
    perPage: { type: Number, default: 1 },
  },
  setup() {
    const { deleteRecord, table } = deleteEntityComposition()
    const { activateEntity, deactivateEntity } = changeEntityStatus()

    return {
      deleteRecord,
      table,
      activateEntity,
      deactivateEntity,
    }
  },
  data() {
    return {
      tableColumns: [
        { key: 'name' },
        { key: 'sub_classifications[0].name', label: 'Sub Classification' },
        { key: 'email' },
        { key: 'phone' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/entity/`,
    }
  },
  methods: {
    avatarText,
  },
}
</script>
<style lang="scss" scoped>

</style>
